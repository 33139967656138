import React, {
	useEffect, useState, useCallback
} from "react";
import styled from "styled-components";
import { infoDialog } from "components/dialogs/AlertDialog";
import Layout from "components/layout/Layout";
import Page from "components/layout/Page";
import useAuth from "hooks/useAuth";
import useDialog from "hooks/useDialog";
import CreateEditProject from "pages/admin/createEditProject";
import OverlayModal from "components/dialogs/OverlayModal";
import IconButton from "components/buttons/IconButton";
import IconAndTextButton from "components/buttons/IconAndTextButton";
import useApi, { API_DELETE } from "hooks/useApi";

const StyledComponent = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 0.5rem 0;
	align-items: center;
	font-size: small;
	border-bottom: 1px solid var(--lines-color);

	.iconAndName, .options, .client, .project {
		display: flex;
		align-items: center;
	}

	.options {
		flex-shrink: 0;
		min-width: 33%;
		padding-left: 0.5rem;
		justify-content: flex-end;
	}

	.iconAndName {
		min-width: 34%;
	}

	.project {
		color: var(--headline-dark);
		font-weight: bold;
		margin-left: 0.5rem;
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.client {
		flex-shrink: 0;
		min-width: 33%;
		padding-left: 0.5rem;
	}
`;

const ListProjects = () => {
	const { isLoggedIn } = useAuth();
	const { presentDialog, presentModal, dismissModal } = useDialog();
	const [projectList, setProjectList] = useState();
	const api = useApi();

	const getProjects = useCallback(() => {
		api({ path: "/projects" }).then((data) => {
			setProjectList(data);
		}).catch((e) => {
			presentDialog(infoDialog("Error", `Could not get project list ${e}`));
		});
	}, [api, presentDialog]);

	const closeAndUpdate = () => {
		dismissModal();
		getProjects();
	};

	const editProject = (project) => {
		presentModal(
			<OverlayModal
				rightButtons={[<button type="button" onClick={dismissModal}>Cancel</button>]}
				title={`${project ? "Edit" : "New"} Project`}
			>
				<CreateEditProject project={project} inModal onComplete={closeAndUpdate} />
			</OverlayModal>
		);
	};

	const deleteProject = (project) => {
		presentDialog(infoDialog("Delete project", `Are you sure you want to delete ${project.displayName}?`, () => {
			// eslint-disable-next-line no-underscore-dangle
			api({ method: API_DELETE, path: `/projects/${project._id}` })
				.then(() => {
					presentDialog(infoDialog("Deleted", "Project deleted successfully"));
				})
				.catch((e) => {
					presentDialog(infoDialog("Error", `Could not delete the project ${e}`));
				})
				.finally(() => {
					getProjects();
				});
		}));
	};

	useEffect(() => {
		if (isLoggedIn && !projectList) {
			getProjects();
		}
	}, [getProjects, isLoggedIn, projectList]);

	// eslint-disable-next-line no-unused-vars
	let currClient = "";
	const listing = projectList?.sort(
		(p1, p2) => {
			if (p1.client < p2.client) {
				return -1;
			}
			if (p1.client > p2.client) {
				return 1;
			}
			return 0;
		}
	).map((project) => {
		currClient = project.client;
		return (
			<StyledComponent key={project.id}>
				<div className="project">{project.displayName}</div>
				<div className="client">{project.client}</div>
				<div className="options">
					<IconButton
						label="Edit Project"
						onClick={() => editProject(project)}
						icon="ant-design:edit-outlined"
					/>
					<IconButton
						onClick={() => deleteProject(project)}
						label="Delete Project"
						icon="ant-design:delete-outlined"
					/>
				</div>
			</StyledComponent>
		);
	});

	return (
		<Layout>
			<Page
				minHeight="75vh"
				middle={(
					<div style={{ marginTop: "5rem" }}>
						<h3>Projects</h3>
						<p>
							{listing}
						</p>
						<p>
							<IconAndTextButton
								text="Add Project"
								onClick={() => editProject()}
								icon="ant-design:plus-square-outlined"
							/>
						</p>
					</div>
				)}
			/>
		</Layout>
	);
};

export default ListProjects;
